import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

export const Mouse = () => {
	const clickyRef = useRef(null);
	const gangRef = useRef(null);

	useEffect(() => {
		if (clickyRef && gangRef) {
			groovin.init(clickyRef.current, gangRef.current);
		}
	}, [clickyRef]);

	return (
		<>
			<div className="clickyBoi" ref={clickyRef} />
			<div className="clickyBoiGang" ref={gangRef} />
		</>
	);
};

const groovin = {
	init: function (clickyRef, gangRef) {
		const cursor = clickyRef,
			follower = gangRef;

		function moveCircle(e) {
			gsap.to(cursor, {
				duration: 0.3,
				x: e.clientX,
				y: e.clientY,
			});

			gsap.to(follower, {
				duration: 0.7,
				x: e.clientX,
				y: e.clientY,
			});
		}

		function hoverFunc(e) {
			gsap.to(cursor, {
				duration: 0.3,
				opacity: 0,
				scale: 0,
			});
			// if ($(e.target).closest('.trigger__video_play').length) {
			//   cursor.classList.add('triggered');
			// } else {
			//   gsap.to(cursor, {
			//     duration: 0.3,
			//     opacity: 0,
			//     scale: 0
			//   });
			// }

			follower.classList.add('triggered');
			gsap.to(follower, {
				duration: 0.3,
				scale: 2.25,
			});
		}

		function unhoverFunc(e) {
			gsap.to(cursor, {
				duration: 0.3,
				opacity: 1,
				scale: 1,
			});
			// if ($(e.target).closest('.trigger__video_play').length) {
			//   cursor.classList.remove('triggered');
			// } else {
			//   gsap.to(cursor, {
			//     duration: 0.3,
			//     opacity: 1,
			//     scale: 1
			//   });
			// }

			follower.classList.remove('triggered');
			gsap.to(follower, {
				duration: 0.3,
				scale: 1,
			});
		}

		window.addEventListener('mousemove', moveCircle);
		[...document.querySelectorAll("a, [class*='trigger__'], input[type='button'], button, .btn")].map(link => {
			link.addEventListener('mouseenter', hoverFunc);
			link.addEventListener('mouseleave', unhoverFunc);
		});
	},
};
