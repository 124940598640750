import React, { ReactNode, useState, useCallback } from 'react'
import { useAtomValue } from 'jotai'
import { useScrollNav } from 'hooks/useStore'
import { fadeIn } from 'hooks/useTween'
import LogoMark from 'assets/svg/blacktop_logo__mark.svg'
import LogoType from 'assets/svg/blacktop_logo__type.svg'

interface HeaderProps {
  children: ReactNode
}

export const Header = ({ children }: HeaderProps) => {
  const scrollNav = useAtomValue(useScrollNav)
  const [logoDims, setLogoDims] = useState({ width: '0', height: '0' })

  const setLogo = useCallback(node => {
    setLogoDims({ width: node.clientHeight, height: node.clientWidth })
  }, [])

  const setHeader = useCallback(node => {
    fadeIn({ el: node, duration: 2, delay: 2 }).play()
  }, [])

  return (
    <header className="header opacity-0" ref={setHeader}>
      <div className="h-full relative z-10 flex md:flex-col items-center justify-between gap-3 border-t border-white border-opacity-50 md:border-none py-3 md:pt-0">
        <a href="#blacktop" className="header__logo" onClick={scrollNav}>
          <LogoMark className="brand block w-8 h-8 md:w-12 md:h-12 mx-auto p-2 pl-0 md:p-0 box-content" />
        </a>

        <div className="header__navigation flex md:flex-col space-x-3 md:space-x-0 md:space-y-5 items-center">
          {children}
        </div>

        <div
          className="header__label vert__label hidden md:flex"
          style={logoDims}
        >
          <h2 className="vert__container" ref={setLogo}>
            <a
              href="#blacktop"
              className="block transform translate-y-1/2 svg__md asset-em text-yellow"
              onClick={scrollNav}
            >
              <LogoType />
            </a>
          </h2>
        </div>
      </div>
    </header>
  )
}
