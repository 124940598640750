// Colors
//
export const brandColors = {
  // Primary
  yellow: 0xf0ff63,

  // Gray tones
  freshasph: 0x161c1e,
  asphalt: 0x1d2628,
  gray__800: 0x343c3d,
  gray__700: 0x4a5153,
  gray__600: 0x616769,
  gray__500: 0x777d7e,
  gray__400: 0x8e9393,
  gray__300: 0xa5a8a9,
  gray__200: 0xbbbebf,
  gray__100: 0xd2d4d4,
  gray__50: 0xe8e9e9,

  // Muted
  // blue: 0x4339db,
  // pink: 0xdec0d8,
  // orange: 0xce6f5e,
  // purple: 0x936fc2,

  // Vibrant
  blue: 0x2b26ef,
  pink: 0xe574bc,
  orange: 0xf05a24,
  // purple: 0x7822aa,
  purple: 0x5112b7,
  teal: 0x6df7d9,
}

// Brand release landing page sections
//
export const releaseSegments = [
  { id: 'blacktop', label: 'Blacktop' },
  { id: 'services', label: 'Our Services' },
  { id: 'work', label: 'Our Work' },
  { id: 'team', label: 'Our Team' },
  { id: 'contact', label: 'Get in Touch' },
]
