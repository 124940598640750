import React, { ReactNode, useState, useEffect } from 'react'
import { useSetAtom, useAtomValue } from 'jotai'
import { Helmet } from 'react-helmet'
import { FormspreeProvider } from '@formspree/react'
import { BackDrop } from 'components/backdrop/BackDrop'
import { Mouse } from 'components/mouse/Mouse'
import { Header } from 'components/header/Header'
import { HeaderLink } from 'components/header/HeaderLink'
import { Footer } from 'components/footer/Footer'
import { SmoothScroll } from 'hooks/useScroll'
import { useLocoScroll, useScrollNav } from 'hooks/useStore'
import { helmet } from 'utils/helmet'
import { releaseSegments } from 'utils/constants'
import { brandColors } from 'utils/constants'

import 'styles/brand.scss'
import 'styles/utils.scss'

interface AppLayoutProps {
  children: ReactNode
}

interface ShellProps {
  children: ReactNode
  navHighlight: String
  setNavHighlight: Function
}

const Shell = ({ children, setNavHighlight }: ShellProps) => {
  return (
    <SmoothScroll
      setNavHighlight={setNavHighlight}
      options={{
        smooth: true,
        smoothMobile: true,
        lerp: 0.09,
      }}
    >
      {children}
    </SmoothScroll>
  )
}

// tslint:disable no-default-export
export default ({ children }: AppLayoutProps) => {
  const [navHighlight, setNavHighlight] = useState('#blacktop')
  const locoScroll = useAtomValue(useLocoScroll)
  const setScrollNav = useSetAtom(useScrollNav)

  useEffect(() => {
    if (locoScroll) {
      const handleLink = e => {
        e.preventDefault()
        const id = e.currentTarget.getAttribute('href')

        if (locoScroll && id) {
          const domEl = document.querySelector(id)
          domEl && locoScroll.scrollTo(domEl)
        }
      }
      setScrollNav(() => handleLink)
    }
  }, [locoScroll])

  return (
    <>
      <Helmet {...helmet} />
      <FormspreeProvider project="1582108480813137310">
        <Header>
          {releaseSegments.map((s, i) => (
            <HeaderLink
              key={s.id}
              name={s.id}
              to={`#${s.id}`}
              label={s.label}
              navHighlight={navHighlight}
            />
          ))}
        </Header>

        <Shell navHighlight={navHighlight} setNavHighlight={setNavHighlight}>
          <>
            {children}

            <Footer />
          </>
        </Shell>
      </FormspreeProvider>

      <BackDrop
        colors={{
          highlightColor: brandColors.blue,
          midtoneColor: brandColors.purple,
          lowlightColor: brandColors.orange,
          baseColor: brandColors.asphalt,
        }}
        texture={{
          blurFactor: 1.4,
          speed: 0.7,
          zoom: 0.2,
          octaves: 4,
        }}
      />
      <Mouse />
    </>
  )
}
