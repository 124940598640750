import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

if (typeof window !== 'undefined') {
	gsap.registerPlugin(SplitText);
}

interface tweenProps {
	el: HTMLDivElement;
	duration?: number;
	delay?: number;
	ease?: string;
}

interface scrollProps {
	el: HTMLDivElement;
	sectionRange?: string;
	scroller?: string;
}

// Basic fadeIn
//
export const fadeIn = ({ el, duration, delay, ease }: tweenProps) => {
	if (!el || typeof window === 'undefined') return false;

	const __TL = gsap.timeline({ paused: true });
	gsap.set(el, { opacity: 0 });
	el.classList.remove('opacity-0');
	__TL.to(el, {
		duration: duration,
		opacity: 1,
		ease: ease,
		delay: delay,
	});

	return __TL;
};

fadeIn.defaultProps = {
	duration: 0.5,
	delay: 0,
	ease: 'power3.out',
};

// Intro Splittext on words
//
export const hello = ({ el }: tweenProps) => {
	if (!el || typeof window === 'undefined') return false;

	const hEl = el.querySelector('h1');
	if (!hEl) return false;

	const sT = new SplitText(hEl, { type: 'words' });
	const words = sT.words;

	el.classList.remove('opacity-0');
	gsap.set(hEl, { perspective: 400 });

	const __TL = gsap.timeline({
		paused: true,
		onComplete: () => {
			sT.revert();
		},
	});
	__TL
		.from(el, {
			duration: 1,
			opacity: 0,
			ease: 'power3.out',
		})
		.from(
			words,
			{
				duration: 1,
				opacity: 0,
				y: 60,
				rotationX: -90,
				transformOrigin: '0% 50% -50',
				ease: 'power3.out',
				stagger: 0.025,
			},
			'+=0',
		);

	return __TL;
};

// ScrollTrigger fade out on scroll
//
export const fadeOutOnScroll = ({ el, sectionRange, from = 0, to = 1 }: scrollProps) => {
	if (!el || typeof window === 'undefined') return false;

	gsap.fromTo(
		el,
		{ opacity: from },
		{
			scrollTrigger: {
				trigger: sectionRange,
				scroller: '#App',
				scrub: true,
				start: 'bottom bottom',
				end: 'bottom top',
			},
			opacity: to,
		},
	);
};
