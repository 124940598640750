import React, { useState, useEffect, useRef } from 'react'

import * as THREE from 'three'
import FOG from 'shades/vanta.fog.js'

import { brandColors } from 'utils/constants'

// Sets
const colorset__1 = {
  highlightColor: brandColors.blue,
  midtoneColor: brandColors.purple,
  lowlightColor: brandColors.pink,
  baseColor: brandColors.asphalt,
}

const colorset__2 = {
  highlightColor: brandColors.blue,
  midtoneColor: brandColors.purple,
  lowlightColor: brandColors.pink,
  baseColor: brandColors.orange,
}

const colorset__3 = {
  accentColor: brandColors.blue,
  highlightColor: brandColors.purple,
  midtoneColor: brandColors.orange,
  lowlightColor: brandColors.pink,
  baseColor: brandColors.blue,
}

const grayset__1 = {
  highlightColor: brandColors.asphalt,
  midtoneColor: brandColors.purple,
  lowlightColor: brandColors.pink,
  baseColor: brandColors.asphalt,
}

const grayset__2 = {
  highlightColor: brandColors.freshasph,
  midtoneColor: brandColors.gray__600,
  lowlightColor: brandColors.gray__300,
  baseColor: brandColors.asphalt,
}

const grayset__3 = {
  highlightColor: brandColors.freshasph,
  midtoneColor: brandColors.asphalt,
  lowlightColor: brandColors.gray__800,
  baseColor: brandColors.asphalt,
}

const texture__oily__1 = {
  blurFactor: 1.5,
  speed: 1,
  zoom: 0.08,
}

const texture__oily__2 = {
  blurFactor: 0.05,
  speed: 2,
  zoom: 0.1,
}

const texture__oily__3 = {
  blurFactor: 1.4,
  speed: 0.7,
  zoom: 0.1,
  octaves: 3,
}

const texture__cloudy__1 = {
  blurFactor: 0.75,
  speed: 2,
  zoom: 0.2,
  octaves: 3,
}

interface BackDropProps {
  colors?: object
  texture?: object
}

export const BackDrop = ({ colors, texture }: BackDropProps) => {
  const BGRef = useRef(null)
  const [BG, setBG] = useState(null)
  const [BGSize, setBGSize] = useState({
    width: 1024,
    height: 1024,
  })
  const [mounted, setMounted] = useState(false)
  const bgTexture = {
    ...texture__oily__3,
    ...texture,
  }
  const bgColors = {
    ...colorset__1,
    ...colors,
  }

  useEffect(() => {
    if (window !== undefined && !mounted) {
      setMounted(true)
      setBGSize({
        width: window.outerWidth,
        height: window.innerHeight,
      })
      return () => setMounted(false)
    }
  }, [])

  useEffect(() => {
    if (mounted && BGRef.current && !BG) {
      const FOGGY = FOG({
        el: BGRef.current,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: true,
        minHeight: BGSize.height,
        minWidth: BGSize.width,
        ...bgTexture,
        ...bgColors,

        // HALO
        // baseColor: 0x823d87,
        // backgroundColor: 0x1D2628
      })

      setBG(FOGGY)
    }
    return () => {
      BG && BG.destroy()
    }
  }, [mounted])

  return (
    <div className="BG__SHELL fixed inset-0 !m-0 flex items-center justify-center z-1">
      <div className="BGCOVER absolute inset-0 bg-gray-900 z-10"></div>
      <div className="BG w-full h-full" ref={BGRef}></div>
    </div>
  )
}
