import React from 'react'
import { useAtomValue } from 'jotai'
import { useScrollNav } from 'hooks/useStore'
import LogoType from 'assets/svg/blacktop_logo__type.svg'

export const Footer = () => {
  const scrollNav = useAtomValue(useScrollNav)
  return (
    <footer id="Footer" className="footer" data-scroll-section>
      <div className="footer__content w-full mx-12">
        <h6 className="mb-4">Thanks for scrolling</h6>
        <a
          href="#blacktop"
          className="footer__logo scrollLink text-yellow"
          onClick={scrollNav}
        >
          <LogoType />
        </a>
      </div>
    </footer>
  )
}
