import React, {
  ReactNode,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import { useAtom } from 'jotai'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { fadeIn, fadeOutOnScroll } from 'hooks/useTween'
import { useLocoScroll } from 'hooks/useStore'

interface ScrollProps {
  children: ReactNode
  options: object
  setNavHighlight: Function
}

export const SmoothScroll = ({
  children,
  options,
  setNavHighlight,
}: ScrollProps) => {
  const [locoScroll, setScroll] = useAtom(useLocoScroll)
  const [proxyReady, setProxyReady] = useState(false)
  const mainRef = useRef()

  // Init LocoScroll
  const setMainRef = useCallback(node => {
    if (!locoScroll && node && typeof window !== 'undefined') {
      ;(async () => {
        try {
          const LocomotiveScroll = (await import('locomotive-scroll')).default

          const headerRef = document.querySelector('.header > div')
          setScroll(
            new LocomotiveScroll({
              el: node,
              ...options,
              scrollbarContainer: headerRef ?? false,
              offset: ['100%', '100%'],
            }),
          )
        } catch (error) {
          throw Error(`[ScrollProvider]: ${error}`)
        }
      })()
    }

    mainRef.current = node
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Init Proxy for LocoScroll, so GSAP can reference it
  useEffect(() => {
    // Handle loco scroller setup
    if (locoScroll && mainRef.current) {
      // Reference segments
      const segments = mainRef.current.querySelectorAll(
        '[data-scroll-section]:not(#blacktop)',
      )

      // Set up gsap and onScroll functions
      gsap.registerPlugin(ScrollTrigger)
      locoScroll.on('scroll', () => {
        ScrollTrigger.update()

        const active = [...(segments || [])].find(el =>
          ScrollTrigger.isInViewport(el),
        )
        const activeId = active?.id ?? 'blacktop'

        if (activeId) {
          setNavHighlight(`#${activeId}`)
        }
      })

      // Set ScrollTrigger to proxy locoscroll
      ScrollTrigger.scrollerProxy(mainRef.current, {
        scrollTop(value) {
          return arguments.length
            ? locoScroll.scrollTo(value, 0, 0)
            : locoScroll.scroll.instance.scroll.y
        },

        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          }
        },

        pinType: mainRef.current.style.transform ? 'transform' : 'fixed',
      })

      ScrollTrigger.addEventListener('refresh', () => locoScroll.update())
      ScrollTrigger.refresh()
      setProxyReady(true)
    }
  }, [locoScroll])

  // Animations for when scroll is ready
  //
  useEffect(() => {
    if (proxyReady && mainRef.current) {
      const BGEl = document.querySelector('.BGCOVER')
      fadeOutOnScroll({
        el: BGEl,
        sectionRange: '#blacktop',
        from: 0.4,
        to: 1,
      })

      ScrollTrigger.refresh()
      const tween = fadeIn({ el: mainRef.current, duration: 1 })
      tween.play()
    }
  }, [proxyReady])

  return (
    <main
      id="App"
      className="app relative flex flex-col space-y-32  md:space-y-64 3xl:space-y-72 z-40 opacity-0"
      ref={setMainRef}
      data-scroll-container
    >
      {children}
    </main>
  )
}
