import React, { ReactNode } from 'react'
import { Link } from 'components/link/Link'
import { useAtomValue } from 'jotai'
import { useScrollNav } from 'hooks/useStore'
import NavSolid from 'assets/svg/circle__solid.svg'
import NavStroke from 'assets/svg/circle__stroke.svg'

interface HeaderLinkProps {
  name: string
  to: string
  label: string
  icon?: ReactNode
  navHighlight: String
}

interface NavMarkerProps {
  active: boolean
}

const NavMarker = ({ active }: NavMarkerProps) => {
  return (
    <div className="relative flex items-center justify-center w-6 h-6">
      <div
        className={[
          'asset w-2 text-white text-opacity-50 transform transition duration-150',
          active ? 'scale-0' : 'scale-100',
        ].join(' ')}
      >
        <NavSolid />
      </div>
      <div
        className={[
          'absolute inset-0 asset text-yellow transform transition duration-150',
          active ? ' scale-100' : 'scale-0',
        ].join(' ')}
      >
        <NavStroke />
      </div>
    </div>
  )
}

export const HeaderLink = ({
  name,
  to,
  icon,
  navHighlight,
}: HeaderLinkProps) => {
  const scrollNav = useAtomValue(useScrollNav)
  const isLink = typeof to !== 'undefined'
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '')
  const isScroll = isLink && to.indexOf('#') === 0

  const content = () => (
    <>
      {icon && React.cloneElement(icon as any, { className: 'link__icon' })}
      <NavMarker active={navHighlight === to} />
      <span className="sr-only">{name}</span>
    </>
  )

  if (isExternal) {
    return (
      <a className="link" target="_blank" rel="noopener noreferrer" href={to}>
        {content()}
      </a>
    )
  }

  if (isScroll) {
    return (
      <a className="scrollLink" href={to} onClick={scrollNav}>
        {content()}
      </a>
    )
  }

  return (
    <Link className="link" to={to}>
      {content()}
    </Link>
  )
}
