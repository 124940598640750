import favicon from 'assets/img/favicon.png'
import share from 'assets/img/share.png'

const title = 'Blacktop'
const description =
  'We help your brand or business create a bespoke website paired with cohesive brand visuals and distinctive messaging in order to speak to, resonate with, and motivate your audience to action.'

export const helmet = {
  title,
  titleTemplate:
    'Blacktop - Web design, development, brand visual, and messaging',
  htmlAttributes: { lang: 'en' },
  meta: [
    { name: 'description', content: description },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, user-scalable=no',
    },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#000' },
    { name: 'msapplication-TileColor', content: '#000' },
    { name: 'theme-color', content: '#000' },

    { property: 'og:title', content: title },
    { property: 'og:image', content: share },
    { property: 'og:image:width', content: '880px' },
    { property: 'og:image:height', content: '440px' },
    { property: 'og:image:alt', content: description },

    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: share },
    { name: 'twitter:site', content: '@_ralph_haynes' },
    { name: 'twitter:creator', content: '@_ralph_haynes' },
    { name: 'twitter:description', content: description },
  ],
  link: [{ rel: 'icon', type: 'image/x-icon', href: favicon }],
}
